import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const SustainableFinanceDisclosureRegulationPolicy = () => {
  return (
    <Layout>
      <SEO title="Shareholder Engagement Policy" />
      <LandingBanner title="Sustainable Finance Disclosure Regulation" alt />
      <section className="internal-page__section">
        Sustainable Finance Disclosure Regulation (“SFDR”)
        <h2>1. Introduction</h2>
        <p>
          This document fulfils the disclosure requirements under Articles 3, 4 and 5 of Regulation
          (EU) 2019/2088 &nbsp;of the European Parliament and of the Council of 27 November 2019 on
          sustainability-related disclosures in the financial services sector (&quot;SFDR&quot;) for
          W&amp;W Asset Management Ireland DAC (&quot;W&amp;W Asset Management&quot; or the
          &quot;Firm&quot;) as a financial market participant and financial advisor within the
          meaning of the SFDR and articulates the Firm's approach to the consideration of
          sustainability risks in the context of the Firm's activities.
        </p>
        <p>
          In accordance with Article 3 of the SFDR, financial market participants and financial
          advisors, including firms authorised as a UCITS manager and AIFM, such as the Firm, are
          required to publish information on their websites regarding their strategies for
          integrating sustainability risks in their investment decision-making processes. The Firm
          prepares a summary of these strategies under&nbsp;5. Strategies for integrating
          sustainability risks in our investment decision-making processes.&nbsp;
        </p>
        <p>
          Where financial market participants, such as the Firm, do not take into account adverse
          impacts of investment decisions on sustainability factors, they are required to publish
          clear reasons for why they do not do so in accordance with Article 4(1)(b) of SFDR.
          W&amp;W Asset Management does not take into account the adverse effects of investment
          decisions on sustainability factors, within the meaning of SFDR, and prepares a summary
          explanation for this under&nbsp;6. No Consideration of Adverse Impacts&nbsp;below.&nbsp;
        </p>
        <p>
          Furthermore, in accordance with Article 5(1)1 of the SFDR, financial market participants
          shall include in their remuneration policies information on how those policies are
          consistent with the integration of sustainability risks and shall publish that information
          on their website as further detailed under&nbsp;8. Remuneration Policy.&nbsp;
        </p>
        <p>
          As part of the process of integrating, environmental, social and corporate governance
          (&quot;ESG&quot;) risks into the investment-decision making process and complying with the
          requirements around transparency regarding the integration of sustainability risks,
          Article 6 of the SFDR requires financial market participants, such as the Firm,
          &nbsp;where they deem sustainability risks to be relevant, to include in pre-contractual
          disclosures to its investors, a description of the manner in which sustainability risks
          are integrated into their investment decisions and the results of the assessment of the
          likely impacts of sustainability risks on the returns of the funds under management.
          &nbsp;W&amp;W Asset Management shall comply with these requirements in its pre-contractual
          disclosures to investors for its funds under management and keep these disclosures under
          periodic review. &nbsp;
        </p>
        <h2>2. Company Background</h2>
        <p>
          W&amp;W Asset Management is a wholly owned subsidiary of W&amp;W Asset Management GmbH, a
          member of W&uuml;stenrot &amp; W&uuml;rttembergische AG or W&amp;W AG (the &quot;W&amp;W
          Group&quot;). The Firm was incorporated in Ireland in 1999 and aims to deliver a
          comprehensive and flexible fund management service offering centred around our clients. We
          provide a gateway for establishing, launching and managing fund structures in partnership
          with our clients.
        </p>
        <p>
          The Firm is regulated in Ireland as a UCITS management company and AIFM and provides fund
          management services to the W&amp;W Group in addition to external investors. The W&amp;W
          Group is one of Germany&rsquo;s oldest and largest financial services and insurance
          groups, employing 13,000 people with headquarters in Stuttgart, Germany and listed on the
          German stock exchange. W&amp;W AG came into existence in 1999 as a result of the merger of
          the two long-standing companies W&uuml;stenrot and W&uuml;rttembergische. Today, the
          W&amp;W Group develops and provides the four components of modern financial planning:
          financial security, housing property, risk protection and savings and investment.&nbsp;
        </p>
        <h2>3. Fund Management services and activities&nbsp;</h2>
        <p>
          The Firm is authorised to perform collective portfolio management activities and services
          under UCITS and AIFMD Regulations in accordance with annex 1 and schedule 1 of those
          Regulations respectively.&nbsp;
        </p>
        <p>
          As at the date of this Policy, the Firm does not provide investment advice, safe-keeping
          and administration in relation to shares or units of collective investment undertakings or
          the reception and transmission of orders in relation to financial instruments.
        </p>
        <p>
          The Firm delegates portfolio management to a number of investment managers and
          administration activities.
        </p>
        <p>
          The Firm is responsible for providing collective portfolio management to spread across two
          Irish based umbrella UCITS collective investment schemes and two umbrella Qualifying
          Investor Alternative Investment Funds.
        </p>
        <p>
          [With effect from 1 August 2022, as a UCITS management company and AIFM, the Firm is
          required to comply with Commission Delegated Directive (EU) 2021/1270 of 21 April 2021
          amending Directive 2010/43/EU as regards the sustainability risk and sustainability
          factors to be taken into account for UCITS and Commission Delegated Regulation (EU)
          2021/1255 amending Delegated Regulation (EU) No 231/2013 as regards the sustainability
          risks and sustainability factors to be taken into account by AIFMs (the &quot;Delegated
          Acts&quot;). The Delegated Acts require sustainability risks to be taken into account in
          the Firm's organisational procedures, resources, the management of conflicts of interest
          and risk management policies.]
        </p>
        <p>
          The Firm does not provide investment services in respect of funds which &ldquo;promotes,
          among other characteristics, environment or social characteristics of a combination of
          those characteristics&ldquo; (known as Article 8 funds) or funds which &ldquo;have
          sustainable investment as its objective and an index that has been designated as a
          reference benchmark&ldquo; (known as Article 9 funds).
        </p>
        <h2>4. W&amp;W Group Strategies for Sustainability and ESG</h2>
        <p>
          The W&amp;W Group considers that it seeks to conducts its business in an environmentally
          compatible, socially responsible and economically successful manner in consideration of
          the interests of current and future generations, for whom the W&amp;W Group considers it
          is responsible.&nbsp;
        </p>
        <p>
          In May 2020, W&amp;W AG signed the UN Principles for Responsible Investment
          (&quot;UNPRI&quot;), thereby undertaking to incorporate ESG issues into its analysis and
          decision-making processes in the investment area. The UN Principles for Responsible
          Investment is an international organization that works to promote the incorporation of
          environmental, social, and corporate governance factors (ESG) into investment
          decision-making. Launched in April 2006, with support from the United Nations (UN), the
          UNPRI has over 3,000 participating financial institutions, as of January 2021. These
          institutions participate by becoming signatories to the UNPRI&rsquo;s six key principles
          and then filing regular reports on their progress. The six key principles to which
          signatory companies must agree to commit themselves are:
        </p>
        <ol>
          <li>
            <p>
              We will incorporate ESG issues into investment analysis and decision-making
              processes.&nbsp;
            </p>
          </li>
          <li>
            <p>
              We will be active owners and incorporate ESG issues into our ownership policies and
              practices.&nbsp;
            </p>
          </li>
          <li>
            <p>
              We will seek appropriate disclosure on ESG issues by the entities in which we invest.
            </p>
          </li>
          <li>
            <p>
              We will promote acceptance and implementation of the Principles within the investment
              industry.
            </p>
          </li>
          <li>
            <p>
              We will work together to enhance our effectiveness in implementing the Principles.
            </p>
          </li>
          <li>
            <p>
              We will each report on our activities and progress towards implementing the
              Principles.
            </p>
          </li>
        </ol>
        <p>
          Many of the Firm&rsquo;s clients have committed themselves either directly to their
          application as a signatory or indirectly as a subsidiary of a signatory of the UNPRI. The
          application of the UNPRI is mandatory for the Firm, all W&amp;W Group companies and
          appointed investment managers of the Firm's funds.&nbsp;
        </p>
        <p>Please see the attached links for further information:</p>
        <ul>
          <li>
            <p>
              Link to the W&amp;W Group ESG Policy -&nbsp;
              <a href="https://www.ww-ag.com/en/about-us/Sustainability">
                https://www.ww-ag.com/en/about-us/Sustainability
              </a>
            </p>
          </li>
          <li>
            <p>
              Link to UNPRI -&nbsp;
              <a href="https://www.unpri.org/signatory-directory/wandw-gruppe/5775.article">
                https://www.unpri.org/signatory-directory/wandw-gruppe/5775.article
              </a>
            </p>
          </li>
          <li>
            <p>
              Link to UNPSI -&nbsp;
              <a href="https://www.unepfi.org/psi/ww-group/">
                https://www.unepfi.org/psi/ww-group/
              </a>
            </p>
          </li>
        </ul>
        <h2>
          5. Strategies for integrating sustainability risks in our investment decision-making
          processes
        </h2>
        <p>
          In accordance with Article 2 of SFDR, a &quot;Sustainability Risk&quot; is an
          &quot;environmental, social or corporate governance event or condition that, if it occurs
          could cause an actual or potential material negative impact on the value of the
          investment.&quot; &nbsp;Sustainability Risks are often referred to as ESG risks.
        </p>
        <p>
          The Firm delegates portfolio management services to a number of investment managers for
          the sub-funds of its UCITS, the W&amp;W Global Strategies Fund and the W&amp;W Global
          Investment Fund and the sub-funds of its QIAIF, the W&amp;W Global Income Fund ICAV and
          the Global Horizons ICAV (the &quot;Funds&quot;). Examples of Sustainability Risks which
          may be taken into consideration by the investment managers are set out in the prospectus
          of each of the Funds. The investment managers' consideration of Sustainability Risks
          depends on the risk profile of the respective investment assets for each sub-fund.
        </p>
        <p>
          As part of its investment decision-making processes the investment managers [are required
          to] consider:
        </p>
        <ol>
          <li>
            <p>
              the level of Sustainability Risks prevalent in markets as part of their active
              selection of the markets in which the Funds will invest;
            </p>
          </li>
          <li>
            <p>
              the Sustainability Risks applicable to individual companies as part of their active
              selection of stocks within each market in which the Funds will invest;
            </p>
          </li>
          <li>
            <p>
              where there are concerns over the ESG practices of a company or there is a higher
              likelihood of Sustainability Risks materialising during the period where the relevant
              sub-fund might be exposed to the company than in other potential companies being
              considered for investment by the sub-fund, this will impact on the investment
              managers' decision of whether and to what degree to pursue such investment, and may
              ultimately result in the relevant investment manager not proceeding with an
              investment.
            </p>
          </li>
        </ol>
        <p>
          The Firm seeks to reduce the likely impact of certain Sustainability Risks by providing as
          stated in each of the Fund's prospectuses that securities are excluded that are prohibited
          under international conventions on cluster munitions and anti-personnel mines in
          accordance with the Ottawa Treaty (1997) and the Convention on Cluster Munitions (2008),
          which prohibit the use, stockpiling, production and transfer of anti-personnel mines and
          cluster munitions respectively. As required under the Funds' prospectuses and also in line
          with the Firm&rsquo;s ESG policy (available upon request) the investment managers employ
          security filtering systems and methodologies as agreed with the Firm to screen proposed
          investments and monitor existing investments of each Fund to ensure compliance with the
          Firm's ESG policy in relation to excluded issuers. Where any exposure to such securities
          of an excluded issuer is identified, the investment manager is required to take corrective
          action to remedy that situation, taking due account of the interests of investors.
        </p>
        <p>
          <b>[Results of the likely impacts of sustainability risks on the returns of the Funds</b>
        </p>
        <p>
          The Firm has assessed the likely impacts of Sustainability Risks on the returns of each of
          the sub-funds of the Funds, and considers it likely that Sustainability Risks will not
          have a material impact on the returns of the sub-funds, given the investment approach of
          the Firm as described above. However, to the extent that the investment managers are
          incorrect in their evaluation of the Sustainability Risks applicable to a particular
          investment of the relevant sub-fund, or a company to which a sub-fund is exposed
          experiences an ESG event or condition that was not considered by the relevant investment
          manager, this could negatively impact upon the returns of the sub-fund.]
        </p>
        <h2>6. No consideration of adverse sustainability impacts&nbsp;</h2>
        <p>
          &quot;Sustainability factors&quot; mean environmental, social and employee matters,
          respect for human rights, anti-corruption and anti-bribery matters.&nbsp;
        </p>
        <p>
          As W&amp;W Asset Management employs fewer than 500 people, there is no requirement for the
          Firm to develop an independent consideration of adverse sustainability impacts. In
          accordance with Article 4(1)(b) of SFDR, W&amp;W Asset Management does not currently
          consider the adverse impacts of its investment decisions on sustainability factors as the
          relevant data are not yet available on the market to a sufficient extent.&nbsp;
        </p>
        <p>
          The Firm may determine to consider sustainability impacts of investment decisions on
          sustainability factors in the future where it proposes to also manage funds within the
          scope of Article 8 and 9 of SFDR and the relevant data is more readily available.&nbsp;
        </p>
        <h2>7. Shareholder Engagement</h2>
        <p>
          The Firm&rsquo;s Shareholder Engagement Policy and annual required disclosures are
          published on its website.
        </p>
        <h2>8. Remuneration Policy</h2>
        <p>
          The Remuneration Policy, which includes information on how the Firm's remuneration
          framework is consistent with the integration of sustainability risks, is published on the
          Firm's website.
        </p>
        <h2>9. Code of Conduct</h2>
        <p>
          The employees of the Firm are obliged to comply with the W&amp;W Group Code of Conduct.
          This Code sets out the minimum standard for the Firm, which regulates the interaction of
          all members of the Firm (employees in the field and in the field, executives, members of
          the board) as well as in relation to customers, competitors, business partners,
          authorities, and our shareholders. This is not only about the practical implementation of
          existing laws and regulations, but also about ethically sound behaviour in our daily work.
        </p>
        <h2>10. Oversight of the Policy</h2>
        <p>
          The Chief Risk Officer bears responsibility for the implementation, oversight and ongoing
          monitoring of the Policy. The Policy will also be subject to approval by the Board of the
          Firm.
        </p>
        <h2>11. Review of and Updates to Policy</h2>
        <p>
          This Policy will be subject to review on at least an annual basis, or upon the occurrence
          of a change to applicable law or the Firm's investment strategy, to ensure that it
          continues to comply with the
        </p>
        <p>
          SFDR and all other relevant applicable laws. The Board will also review and note the
          current version of the W&amp;W Group ESG policy on an annual basis.
        </p>
        <h2>12. Access to Policy</h2>
        <p>
          A copy of this Policy is available to investors of the Funds on the Firm's website. The
          Firm will ensure that all information contained in this Policy, as published, is
          maintained accurate and up to date.
        </p>
      </section>
    </Layout>
  );
};

export default SustainableFinanceDisclosureRegulationPolicy;
